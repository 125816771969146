import { Component, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestApiService } from '@app/@shared/services/rest-api.service';
import { ApplicationUrls } from '@app/core/application-urls';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/web-store/store-shared/toast-service/toast.service';
import { AuthService } from '@app/@shared/services/auth-service/auth.service';

@Component({
  selector: 'app-store-header',
  templateUrl: './store-header.component.html',
  styleUrls: ['./store-header.component.scss']
})
export class StoreHeaderComponent {

  @Input() isUserLoggedIn: boolean = false;
  @Input() userData: any;
  @Output() searchHeaderEvent: EventEmitter<any> = new EventEmitter<any>();

  public searchKeyWord: string = '';
  public showPassword: boolean = false;

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.isUserLoggedIn = changes['isUserLoggedIn'].currentValue;
  //   console.log("inside ngOnChanges", this.isUserLoggedIn);
  // }
  loginForm: FormGroup;
  constructor(private fb: FormBuilder, private restApiService: RestApiService, private router: Router, private toastService: ToastService, private authService: AuthService) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]]
    });
  }

  async ngOnInit(): Promise<void> {
    console.log("inside ngOnInit", this.isUserLoggedIn, "userdata:", this.userData);
    this.simulateLoading();
    await this.authService.keycloakInit();
  }

  keyWordSearchDashboard(): void {
    this.searchHeaderEvent.emit({ searchKeyWord: this.searchKeyWord });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  get loginFormControls() {
    return this.loginForm.controls;
  }

  resetLoginForm() {
    this.loginForm.reset();
    this.showPassword = false;
  }

  async keycloakLogin() {
    await this.authService.keycloaklogin();
  }

  userLogin() {
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Authorization', 'Bearer '+localStorage.getItem('keycloak_token'));
    this.restApiService.post(ApplicationUrls.LOGIN, this.loginForm.value)
      .subscribe({
        next: (res) => {
          console.log(res);
          localStorage.setItem('accessToken', JSON.stringify(res.access_token));
          this.router.navigate(['web-store/store-user'])
        },
        error: (err) => {
          console.log("login errorrr@@@@@@@@@@@: ", err);
          this.toastService.show('Invalid user credentials.', { classname: 'text-bg-danger text-light', delay: 3000 });
        },
        complete: () => console.log("Completed Login")
      })
  }

  isLoading: boolean = true; // Set to true to show the spinner initially

  // Example method to simulate data loading
  simulateLoading() {
    setTimeout(() => {
      this.isLoading = false; // Set to false to hide the spinner when data is loaded
    }, 90000); // Simulate loading time (3 seconds)
  }

  public logout(): void {
    this.authService.keycloakLogout()
      .then((response) => {
        console.log(response);
        this.router.navigate(['/auth/login']).catch();
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
