export class ApplicationUrls {
    public static GET_KEYCLOAK_TOKEN = 'protocol/openid-connect/token';
    public static LOGIN = 'auth/login';
    public static ADVANCE_FILTERS = "search-content-enhancement/advance-filters";
    public static USER_INFO = "users/me";
    public static USERS_AGREEMENT = "users/agreement/published";
    public static MOST_FOLLOWED = "users/mostfollowed";
    public static REPORT_EMAIL = "feedbackmail/webreport";
    public static REQUEST_EMAIL = "feedbackmail/webrequest";
    public static GUEST_USER = "users/guest";

}