import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../@shared/header/header.component';
import { FooterComponent } from '../@shared/footer/footer.component';
import { RestApiService } from './services/rest-api.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SharedService } from './services/shared-service/shared.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { TranslateModule } from '@ngx-translate/core';
import { StoreSharedModule } from "src/app/web-store/store-shared/store-shared.module";
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMaterialModule } from '../../material-module';
import { AuthService } from './services/auth-service/auth.service';

@NgModule({ declarations: [HeaderComponent, FooterComponent],
    exports: [
        HeaderComponent,
        FooterComponent,
        TranslateModule,
        ReactiveFormsModule,
        AngularMaterialModule
    ], imports: [CommonModule,
        RouterModule,
        ReactiveFormsModule,
        StoreSharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        TranslateModule,
        AngularMaterialModule], providers: [RestApiService, SharedService, AuthService, {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule { }
