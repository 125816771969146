import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  public token: string = '';
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.token = localStorage.getItem('accessToken') as string;
    if (this.token) {
      this.token = this.token.replace(/"/g, '');
    }
    const tokenizedReq = (!req.url.includes('mostfollowed') && !req.url.includes('mostviewed') && !req.url.includes('published')) ? req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.token
      }
    }) : req;

    return next.handle(tokenizedReq).pipe(map(res => {
      return res;
    }), catchError((error: HttpErrorResponse) => {
      console.log(`INside HTTP Interceptor catchError the error is ${JSON.stringify(error)}`);
      const errorMessage = error.error.message || 'An error occurred';
      return throwError(() => new Error(errorMessage));
    }))
  }
}
