export const environment = {
    production: true,
    REALM: "Cawaena",
    KEY_CLOAK_CLIENT_ID: "Cawaena_front_app",
    KEY_CLOAK_CLIENT_SECRET: "vmgd6MXABd1xqnXo2US9Bu4IFe5As7Wd",
    KEY_CLOAK_AUTH_SERVER_URL: "https://cawaena-keycloak-dev.azurewebsites.net/auth",
    KEY_CLOAK_IDENTITY_SERVER_URL: "https://cawaena-idm-dev.azurewebsites.net/api/",
    BASE_URL: "https://cawaena-api-stg.azurewebsites.net/api/",
    X_API_KEY: "b7b297c8e4395f1b9c80a3f814cf028eaa3043c20a0e7a584da4a12532b35e92",
    guestHash: 'c2VjcmV0a2V5Zm9yanNvbnNpZ25hdHVyZQ',
    guestHashCostFactor: 10,
    guestHashIAT: 1617878205,
    keycloakUrl:'https://keycloak.stg.cawaena.com/auth',
    realm:'Cawaena',
    clientID:'Cawaena_front_app',
    redirectionURI:'https://cawaenatest.azurewebsites.net/#'
};