<app-header></app-header>
<div class="faq-container bg-black">
    <div class="faq-heading">
        {{"FAQ_CONTENT.heading" | translate}}
    </div>
    <div class="faq-search-container w-100 my-4">
        <!-- <div class="input-group">
            <span class="input-group-text material-icons-outlined pe-0">
                search
            </span>
            <input type="text" placeholder="Search a question..." name="" id="" class="form-control ps-2">
        </div>
        <div class="mt-4">
            <p>or choose a category to quickly find the help you need</p>
        </div> -->
    </div>

    <div class="faq-carousel">
        <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" [interval]="0"
            class="d-none d-sm-none d-md-block">
            <ng-template ngbSlide>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[0].name"
                    (click)="selectTopic(faqTopics[0].name)">
                    <div class="mb-2 text-center">
                        <span class="material-icons-outlined">{{faqTopics[0].icon}}</span>
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[0].name | translate}}
                    </div>
                </div>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[1].name"
                    (click)="selectTopic(faqTopics[1].name)">
                    <div class="mb-2 text-center">
                        <span class="material-icons-outlined">{{faqTopics[1].icon}}</span>
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[1].name | translate}}
                    </div>
                </div>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[2].name"
                    (click)="selectTopic(faqTopics[2].name)">
                    <div class="mb-2 text-center">
                        <img src="assets/icons/privacy_and_control_normal.svg" width="24" height="24" alt="Data protection">
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[2].name | translate}}
                    </div>
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[3].name"
                    (click)="selectTopic(faqTopics[3].name)">
                    <div class="mb-2 text-center">
                        <span class="material-icons-outlined">{{faqTopics[3].icon}}</span>
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[3].name | translate}}
                    </div>
                </div>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[4].name"
                    (click)="selectTopic(faqTopics[4].name)">
                    <div class="mb-2 text-center">
                        <img src="assets/icons/influencer_icon_normal.svg" width="24" height="24" alt="Influencer">
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[4].name | translate}}
                    </div>
                </div>
                <div class="faq-topic-card" [class.faq-topic-active-card]="faqActiveTopic === faqTopics[5].name"
                    (click)="selectTopic(faqTopics[5].name)">
                    <div class="mb-2 text-center">
                        <span class="material-icons-outlined">{{faqTopics[5].icon}}</span>
                    </div>
                    <div class="text-center normal-font-family">
                        {{faqTopics[5].name | translate}}
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
        <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" [interval]="0"
            class="d-block d-sm-block d-md-none">
            <ng-template ngbSlide *ngFor="let faqItem of faqTopics" (slid)="onSlide($event, faqItem.name)">
                <div class="faq-topic-card">
                    <div class="mb-2 text-center">
                        <img *ngIf="faqItem.name === 'FAQ_CONTENT.faq_topic_3'" src="assets/icons/privacy_and_control_normal.svg" width="24" height="24" alt="Data protection">
                        <img *ngIf="faqItem.name === 'FAQ_CONTENT.faq_topic_5'" src="assets/icons/influencer_icon_normal.svg" width="24" height="24" alt="Influencer">
                        <span class="material-icons-outlined" *ngIf="faqItem.name !== 'FAQ_CONTENT.faq_topic_3' && faqItem.name !== 'FAQ_CONTENT.faq_topic_5'">{{faqItem.icon}}</span>
                    </div>
                    <div class="text-center">
                        {{faqItem.name | translate}}
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="faq-qes-ans mx-3">
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_1'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2,3,4,5,6]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_1_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_1_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_2'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2,3,4,5,6,7,8,9,10,11,12]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_2_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_2_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_3'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2,3,4,5,6]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_3_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_3_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_4'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2,3,4,5,6,7,8,9,10,11]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_4_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_4_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_5'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2,3]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_5_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_5_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="faqActiveTopic === 'FAQ_CONTENT.faq_topic_6'">
            <div ngbAccordion [closeOthers]="true" class="faq-accordion">
                <div *ngFor="let faq of [1,2]; let i = index;">
                    <div ngbAccordionItem [collapsed]="i !== faqIndex">
                        <div ngbAccordionHeader class="faq-accordion-header">
                            <button ngbAccordionButton (click)="faqIndex = i" class="justify-content-between px-0 bold-font-family">
                                {{ 'FAQ_CONTENT.faq_topic_6_q' + faq.toString() | translate }} <span
                                    class="material-icons-outlined">
                                    {{faqIndex === i && !showAddIcon ? 'remove' : 'add'}}
                                </span>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="px-0">
                                <ng-template>
                                    <div [innerHTML]="'FAQ_CONTENT.faq_topic_6_a' + faq.toString() | translate" class="normal-font-family"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>