<app-header (languageChanged)="getUserAgreements()"></app-header>
<div class="main-container bg-black text-white">
    <img [src]="'/assets/images/community.png'" alt="community-img">
    <h2 class="my-5 text-center d-none d-sm-none d-md-none d-lg-block">{{"COMMUNITY_GUIDELINES_CONTENT.heading" |
        translate}}</h2>
    <h2 class="my-5 text-center d-block d-sm-block d-md-block d-lg-none">{{"COMMUNITY_GUIDELINES_CONTENT.heading" |
        translate}}</h2>
    <div [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.description' | translate" class="mb-5"></div>

    <div class="guidelines-btn-grp">
        <div class="btn-group rounded-0" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="communityGuidelines" id="communityGuidelines" autocomplete="off"
                (click)="guidelinesBtnActive='communityGuidelines'">
            <label class="btn" for="communityGuidelines"
                [class.guidelines-active-btn]="guidelinesBtnActive=='communityGuidelines'">{{"COMMUNITY_GUIDELINES_CONTENT.tab_option_community" | translate}}</label>
            <input type="radio" class="btn-check" name="contentModeration" id="contentModeration" autocomplete="off"
                (click)="guidelinesBtnActive='contentModeration'">
            <label class="btn" for="contentModeration"
                [class.guidelines-active-btn]="guidelinesBtnActive=='contentModeration'">{{"COMMUNITY_GUIDELINES_CONTENT.tab_option_content_mod" | translate}}</label>
        </div>
    </div>
    <div *ngIf="guidelinesBtnActive==='communityGuidelines'" class="community-guidelines-content">
        <div *ngIf="isLoading" class="loader-symbol">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div [innerHTML]="communityGuidelines" class="legal-content-div">
        </div>
    </div>
    <div *ngIf="guidelinesBtnActive==='contentModeration'">
        <div class="justify-text">
            <div class="mt-4" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_para_1' | translate"></div>
            <div class="my-3">{{"COMMUNITY_GUIDELINES_CONTENT.sub_heading_2" | translate}}</div>
            <div class="mb-4" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_para_2' | translate"></div>

            <div class="mb-3">
                <strong>{{"COMMUNITY_GUIDELINES_CONTENT.sub_heading_3" | translate}}</strong>
            </div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase1_para_1' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase1_para_2' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase1_para_3' | translate"></div>
            <div class="mb-4" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase1_para_4' | translate"></div>

            <div class="mb-3">
                <strong>{{"COMMUNITY_GUIDELINES_CONTENT.sub_heading_4" | translate}}</strong>
            </div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_1' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_2' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_3' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_4' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_5' | translate"></div>
            <div class="mb-4" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase2_para_6' | translate"></div>

            <div class="mb-3">
                <strong>{{"COMMUNITY_GUIDELINES_CONTENT.sub_heading_5" | translate}}</strong>
            </div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase3_para_1' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase3_para_2' | translate"></div>
            <div class="mb-3" [innerHTML]="'COMMUNITY_GUIDELINES_CONTENT.moderation_phase3_para_3' | translate"></div>
        </div>
    </div>
</div>