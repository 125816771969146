import { Component, EventEmitter, Output } from '@angular/core';
import { SharedService } from '../services/shared-service/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Output() languageChanged = new EventEmitter<string>();
  copiedPromoCode = false;

  constructor(private sharedServ: SharedService, private translateService: TranslateService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.userLanguage = params['lang'] || localStorage.getItem("selectedLanguage") || navigator.language.split('-')[0] || 'en';
      console.log("In header languagee @@@@@@@@@@@@@@@@@@@@", this.userLanguage);
      this.translateService.setDefaultLang(this.userLanguage);
      localStorage.setItem("selectedLanguage", this.userLanguage);
      this.translateService.use(this.userLanguage);
      this.languageChanged.emit();
    });
    // this.userLanguage = (localStorage.getItem("selectedLanguage") || navigator.language || 'en').split('-')[0];
    // this.translateService.setDefaultLang(this.userLanguage);
    // localStorage.setItem("selectedLanguage", this.userLanguage);
    // this.translateService.use(this.userLanguage);
    // console.log("Inside setLanguage @@@@@@@@@@@@@@@@@@@@", this.userLanguage);
  }

  ngOnInit(): void {
    this.sharedServ.getSharedLogoUrl().subscribe(value => {
      this.etoLogo = value;
    });
  }
  public supportingLanguages: Array<any> = [{}]
  public etoLogo: string | undefined;
  public isMenuOpen: boolean = false;
  public userLanguage!: string;

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  changeLanguage(selectedLang: string) {
    this.userLanguage = selectedLang;
    this.languageChanged.emit();
    localStorage.setItem('selectedLanguage', selectedLang);
    this.translateService.use(selectedLang);
    // window.location.reload();
  }

  copyPromoCode() {
    navigator.clipboard.writeText((<HTMLElement>document.querySelector(".promo-code-text")).innerText);
    this.copiedPromoCode = true;
  }
}
