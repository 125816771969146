import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules  } from '@angular/router';
import { MainModule } from 'src/app/@pages/main/main.module';
import { WebStoreModule } from './web-store/web-store.module';

const routes: Routes = [
  {
    path: 'main',
    loadChildren: (): Promise<Type<MainModule>> => import('./@pages/main/main.module').then((mod) => mod.MainModule),
  },
  {
    path: 'web-store',
    loadChildren: (): Promise<Type<WebStoreModule>> => import('./web-store/web-store.module').then((mod) => mod.WebStoreModule),
  },
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
