import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/@shared/services/shared-service/shared.service';
import { RestApiService } from '@app/@shared/services/rest-api.service';
import { ApplicationUrls } from '@app/core/application-urls';
import { ToastService } from 'src/app/web-store/store-shared/toast-service/toast.service';

@Component({
  selector: 'app-community-guidelines',
  templateUrl: './community-guidelines.component.html',
  styleUrls: ['./community-guidelines.component.scss']
})
export class CommunityGuidelinesComponent implements OnInit {

  guidelinesBtnActive = 'communityGuidelines';

  constructor(private sharedServ: SharedService, private restApiService: RestApiService, private toastService: ToastService) {
  }

  public communityGuidelines!: string;
  isLoading = false;

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.sharedServ.setSharedLogoUrl('assets/icons/Cawaena_Logo_RGB.svg');
    this.getUserAgreements();
  }

  getUserAgreements() {
    this.isLoading = true;
    this.restApiService.get(ApplicationUrls.USERS_AGREEMENT)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.communityGuidelines = localStorage.getItem("selectedLanguage") === 'de' ? res[2].contentDE : res[2].content;
        },
        error: (err) => {
          console.log("login errorrr@@@@@@@@@@@: ", err);
        },
        complete: () => console.log("GET agreement completed")
      })
  }

}
