import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private http: HttpClient) { }

  private BASE_URL = "https://cawaena-api-rc.azurewebsites.net/api"


  // HTTP header for Backend API's
  public headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('x-api-key', environment.X_API_KEY);

  /**
    * @name get
    * @description To make HTTP GET (retrieve) call to the backend REST API based on the dynamic URL endpoint.
    * @param {string} url backend REST API end point.
    * @param {any} params is an object to pass through the query param. It is optional param.
    * @returns {any} response from the requested backend REST API.
  */
  get(url: string, params?: HttpParams): Observable<any> {
    return this.http.get(this.fullUrl(url), { responseType: 'json', params: params, headers: this.headers });
  }

  /**
    * @name post
    * @description To make HTTP GET (retrieve) call to the backend REST API based on the dynamic URL endpoint.
    * @param {string} url backend REST API end point.
    * @param {any} body Data to be sent to the backend through body.
    * @returns {any} response from the requested backend REST API.
  */
  post(url: string, body: any): Observable<any> {
    return this.http.post(this.fullUrl(url), body, { responseType: 'json', headers: this.headers });
  }

  /**
    * @name postKeyCloak
    * @description To make HTTP GET (retrieve) call to the backend REST API based on the dynamic URL endpoint.
    * @param {string} url backend REST API end point.
    * @param {any} body Data to be sent to the backend through body.
    * @returns {any} response from the requested backend REST API.
  */
  postKeyCloak(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    return this.http.post(this.fullKeyCloakUrl(url), body, { responseType: 'json', headers: headers });
  }


  /**
    * @name put
    * @description To make HTTP PUT (UPDATE) call to the backend REST API based on the dynamic URL endpoint.
    * @param {string} url backend REST API end point.
    * @param {any} body Data to be sent to the backend through body.
    * @returns {any} response from the requested backend REST API.
  */
  put(url: string, body: any): Observable<any> {
    // this.headers = this.headers.append('Authorization', `Bearer ${this.getToken()}`);
    // return this.http.put(`${this.BASE_URL}/${url}`, body, { headers: this.headers });
    return this.http.put(this.fullUrl(url), body, { responseType: 'json', headers: this.headers });
  }

  /**
    * @name delete
    * @description To make HTTP Delete by sending url parameters call to the backend REST API based on the dynamic URL endpoint.
    * @param {string} url backend REST API end point.
    * @param {any} params data to send the backend REST API through url. It is optional.
    * @returns {any} response from the requested backend REST API.
  */
  delete(url: string, params?: HttpParams): Observable<any> {
    return this.http.delete(this.fullUrl(url), { responseType: 'json', params: params, headers: this.headers });
  }

  /**
   * @name fullUrl
   * @description To concatenate the backend BASE_URL with url endpoint.
   * @param {string} url input backend url endpoint to concatenate with the BASE_URL from environment file.
   * @returns {string} full URL to make a request to Backend REST API.
 */
  fullUrl(url: string): string {
    return environment.BASE_URL + url;
  }

  /**
   * @name fullKeyCloakUrl
   * @description To concatenate the backend BASE_URL with url endpoint.
   * @param {string} url input backend url endpoint to concatenate with the BASE_URL from environment file.
   * @returns {string} full URL to make a request to Backend REST API.
 */
  private fullKeyCloakUrl(url: string): string {
    return environment.KEY_CLOAK_IDENTITY_SERVER_URL + environment.REALM + url;
  }

  requestPost(body: any): Observable<any> {
    const reportHeaders = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('x-api-key', "3ae8c1f0637d5297086b04992571da2f8f6e4f99e0e7799c10283d3d45c5ef1e");
    return this.http.post("https://cawaena-api-rc.azurewebsites.net/api/feedbackmail/webrequest", body, { responseType: 'json', headers: reportHeaders });
  }

}
