import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

function generateUUID(): string {
  // Simple UUID generation function
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  private sharedLogoUrl = new BehaviorSubject<string>('assets/icons/white_logo.png');

  setSharedLogoUrl(value: string) {
    console.log(`inside shared service setSharedLogoUrl logoUrl: ${value}`);
    this.sharedLogoUrl.next(value);
  }

  getSharedLogoUrl() {
    return this.sharedLogoUrl.asObservable();
  }

  generateUUID() {
    const uuid = generateUUID();
    localStorage.setItem('guestUserUUID', uuid);
    console.log('Generated UUID: ', uuid);
    return uuid;
  }
}
