<app-header></app-header>
<div class="main-container bg-black text-white">
    <div class="text-center">
        <img [src]="'/assets/images/licensing.png'" alt="licensing img">
        <div class="mt-5 mb-5 main-heading">{{"LICENSE.heading" | translate}}</div>
    </div>
    <div>
        <div class="text-center mb-3">
            <strong>Creative Commons</strong>
        </div>
        <div>{{"LICENSE.CC_DESC" | translate}}</div>
        <div><strong>{{"LICENSE.CC_HEAD" | translate}}</strong></div>
        <div class="mb-4">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY" | translate}} </strong> {{"LICENSE.CC_BY_DESC" | translate}}
        </div>
        <div class="mb-3 pb-4 border-bottom d-flex align-items-center">
            <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
            <div><strong>{{"LICENSE.BY" | translate}}</strong> {{"LICENSE.BY_INFO" | translate}}</div>
        </div>
        <div class="mb-4">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_2.svg'" alt="icon-2" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY_SA" | translate}} </strong> {{"LICENSE.CC_BY_SA_DESC" | translate}}
        </div>
        <div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
                <div><strong>{{"LICENSE.BY" | translate}}</strong> {{"LICENSE.BY_INFO" | translate}} </div>
            </div>
            <div class="mb-3 pb-4 border-bottom d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_2.svg'" alt="icon-1" class="me-2">
                <div> <strong>{{"LICENSE.SA" | translate}}</strong> {{"LICENSE.SA_INFO" | translate}} </div>
            </div>
        </div>
        <div class="mb-4">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY_NC" | translate}} </strong> {{"LICENSE.CC_BY_NC_DESC" | translate}}
            <div class="mb-3"> {{"LICENSE.CC_BY_NC_ELEMENTS" | translate}} </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
                <div><strong>{{"LICENSE.BY" | translate}}</strong> {{"LICENSE.BY_INFO" | translate}}</div>
            </div>
            <div class="mb-3 pb-4 border-bottom d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" class="me-2">
                <div><strong>{{"LICENSE.NC" | translate}} </strong> {{"LICENSE.NC_INFO" | translate}}</div>
            </div>
        </div>
        <div class="mb-4">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_2.svg'" alt="icon-2" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY_NC_SA" | translate}} </strong> {{"LICENSE.CC_BY_NC_SA_DESC" | translate}}
            <div class="mb-3"> {{"LICENSE.CC_BY_NC_SA_ELEMENTS" | translate}} </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
                <div><strong>{{"LICENSE.BY" | translate}}</strong> {{"LICENSE.BY_INFO" | translate}}</div>
            </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" class="me-2">
                <div><strong>{{"LICENSE.NC" | translate}} </strong> {{"LICENSE.NC_INFO" | translate}}</div>
            </div>
            <div class="mb-3 pb-4 border-bottom d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_2.svg'" alt="icon-2" class="me-2">
                <div><strong>{{"LICENSE.SA" | translate}} </strong> {{"LICENSE.SA_INFO" | translate}}</div>
            </div>
        </div>
        <div class="mb-4">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_4.svg'" alt="icon-4" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY_ND" | translate}}</strong> {{"LICENSE.CC_BY_ND_DESC" | translate}}
            <div class="mb-3"> {{"LICENSE.CC_BY_ND_ELEMENTS" | translate}} </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
                <div><strong>{{"LICENSE.BY" | translate}}</strong> {{"LICENSE.BY_INFO" | translate}} </div>
            </div>
            <div class="mb-3 pb-4 border-bottom d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_4.svg'" alt="icon-4" class="me-2">
                <div><strong>{{"LICENSE.ND" | translate}}</strong> {{"LICENSE.ND_INFO" | translate}} </div>
            </div>
        </div>
        <div class="mb-5">
            <div class="text-center mb-3 mt-5">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" class="me-2" width="60px"
                    height="auto">
                <img [src]="'/assets/icons/licensing/licensing_4.svg'" alt="icon-4" width="60px" height="auto">
            </div>
            <strong>{{"LICENSE.CC_BY_NC_ND" | translate}} </strong> {{"LICENSE.CC_BY_NC_ND_DESC" | translate}}
            <div class="mb-3"> {{"LICENSE.CC_BY_NC_ND_ELEMENTS" | translate}} </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_1.svg'" alt="icon-1" class="me-2">
                <div><strong>{{"LICENSE.BY" | translate}} </strong> {{"LICENSE.BY_INFO" | translate}} </div>
            </div>
            <div class="mb-3 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_3.svg'" alt="icon-3" class="me-2">
                <div><strong>{{"LICENSE.NC" | translate}}</strong> {{"LICENSE.NC_INFO" | translate}} </div>
            </div>
            <div class="mb-5 d-flex align-items-center">
                <img [src]="'/assets/icons/licensing/licensing_4.svg'" alt="icon-4" class="me-2">
                <div><strong>{{"LICENSE.ND" | translate}} </strong> {{"LICENSE.ND_INFO" | translate}}</div>
            </div>
        </div>
        <div class="my-4 text-center">
            {{"LICENSE.KNOW_MORE" | translate}}
            <div>
                <a class="links"
                    href="https://creativecommons.org/share-your-work/cclicenses/">{{"LICENSE.OFFICIAL_WEBSITE" |
                    translate}}</a>
            </div>
        </div>
        <div class="mb-5 text-center">
            {{"LICENSE.CHOOSER_TOOL" | translate}}
            <div>
                <a class="links"
                    href="https://chooser-beta.creativecommons.org/">{{"LICENSE.LICENsE_CHOOSER" |
                    translate}}</a>
            </div>
        </div>
    </div>

</div>