<div>
    <div class="container">
        <div class="my-3">
            <img src="assets/icons/logo/Cawaena_Logo_Black.svg" width="120" height="24">
        </div>
        <div class="row mx-0 justify-content-evenly footer-row">
            <div class="col-auto">
                <div class="fw-semibold">
                    About Us
                </div>
                <div class="footer-heading-border"></div>
                <div class="my-3">
                    <a href="">Contact Us</a>
                </div>
                <div class="my-3">
                    <a href="">Sitemap</a>
                </div>
            </div>
            <div class="col-auto">
                <div class="fw-semibold">
                    Browse
                </div>
                <div class="footer-heading-border"></div>
                <div class="my-3">
                    <a href="">Images</a>
                </div>
                <div class="my-3">
                    <a href="">Videos</a>
                </div>
            </div>
            <div class="col-auto">
                <div class="fw-semibold">
                    Need help
                </div>
                <div class="footer-heading-border"></div>
                <div class="my-3">
                    <a href="">
                        Phone support: +1(231) 345 298 56
                    </a>
                </div>
                <div class="my-3">
                    <a href="">
                        International toll-free: +1(536) 946 425 62
                    </a>
                </div>
            </div>
        </div>
        <div class="my-4" style="border: 0.5px solid #9C9999;"></div>
        <div class="row mx-0 justify-content-between">
            <div class="col-auto footer-links">
                <a class="mx-2" href="">Licencse Information</a>
                <a class="mx-2" href="">Terms of use</a>
                <a class="mx-2" href="">Privacy policy</a>
                <a class="mx-2" href="">Cookie policy</a>
            </div>
            <div class="col-auto">
                <img src="assets/icons/Social-media-icons.svg" width="180" height="30" alt="">
            </div>
        </div>
    </div>
</div>