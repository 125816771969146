import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './@shared/shared.module';
import { FaqHelpComponent } from './@pages/faq-help/faq-help.component';
import { LicensingComponent } from './@pages/licensing/licensing.component';
import { CommunityGuidelinesComponent } from './@pages/community-guidelines/community-guidelines.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastsContainerComponent } from "./web-store/store-shared/toasts-container/toasts-container.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FaqHelpComponent,
    LicensingComponent,
    CommunityGuidelinesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastsContainerComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
