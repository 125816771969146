<!-- <div class="spinner-overlay" *ngIf="isLoading">
    <mat-spinner class="center-spinner"></mat-spinner>
</div> -->
<div class="mx-auto header-bg w-100 py-3">
    <div class="container row mx-auto justify-content-between">
        <div class="col-auto">
            <img src="assets/icons/logo/Cawaena_Logo_Black.svg" class="cursor-pointer" width="160" height="32" alt="Caweana Logo" [routerLink]="isUserLoggedIn ? '../store-user' : '../../main/home'">
        </div>
        <!-- <div class="col-auto w-50 d-flex align-items-center">
            <input type="text" class="form-control" [(ngModel)]="searchKeyWord" placeholder="Search Images">
            <mat-icon class="material-icons-outlined send-icon" (click)="keyWordSearchDashboard()">send</mat-icon>
        </div> -->

        <div class="col-auto">
            <div *ngIf="!isUserLoggedIn" class="d-flex">
                <div>
                    <button class="btn col-auto">All</button>
                </div>
                <div>
                    <button class="btn col-auto">Images</button>
                </div>
                <div>
                    <button class="btn col-auto">Videos</button>
                </div>
                <div>
                    <button class="btn col-auto">Stories</button>
                </div>
            </div>
            <div class="row" *ngIf="isUserLoggedIn">
                <div class="col-auto">
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                        <mat-icon class="material-icons-outlined">shopping_cart</mat-icon>
                    </button>
                </div>


                <!-- <div class="col-auto">
                    <div ngbDropdown class="d-inline-block">
                        <button mat-icon-button class="menu-icon" aria-label="icon-button with menu icon"
                            ngbDropdownToggle>
                            <mat-icon>menu</mat-icon>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="typesMenu">
                            <div ngbDropdownItem>
                                <div class="form-check d-flex flex-column">
                                    <span class="cursor-pointer" routerLink="../store-user">Home</span>
                                    <span class="cursor-pointer" routerLink="../user-profile">My Profile</span>
                                    <span class="cursor-pointer" routerLink="../user-profile">Settings</span>
                                    <span class="cursor-pointer" (click)="logout()">Logout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="col-auto">
                    <div ngbDropdown class="d-inline-block">
                        <button mat-icon-button class="menu-icon" aria-label="icon-button with menu icon" ngbDropdownToggle>
                            <mat-icon>menu</mat-icon>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="typesMenu">
                            <div ngbDropdownItem>
                                <div class="ps-2 d-flex flex-column">
                                    <span class="cursor-pointer d-flex align-items-center mb-2" routerLink="../store-user">
                                        <mat-icon class="me-2">home</mat-icon> Home
                                    </span>
                                    <span class="cursor-pointer d-flex align-items-center mb-2" routerLink="../user-profile">
                                        <mat-icon class="me-2">person</mat-icon> My Profile
                                    </span>
                                    <span class="cursor-pointer d-flex align-items-center mb-2" routerLink="../user-profile">
                                        <mat-icon class="me-2">settings</mat-icon> Settings
                                    </span>
                                    <span class="cursor-pointer d-flex align-items-center mb-2" (click)="logout()">
                                        <mat-icon class="me-2">logout</mat-icon> Logout
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div *ngIf="!isUserLoggedIn" class="col-auto">
            <!-- <button class="login-link-btn btn" data-bs-toggle="modal" data-bs-target="#loginModal">Login</button> -->
            <button class="login-link-btn btn" (click)="keycloakLogin()">Login</button>

        </div>
    </div>
</div>

<!-- Modal for Login starts -->
<div class="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" id="myProfileModal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="resetLoginForm()"></button>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column align-items-center">
                    <img src="assets/icons/logo/Cawaena_Logo_Black.svg" width="160" height="32" alt="Caweana Logo">
                    <h5 class="modal-title welcome-back-text my-4" id="staticBackdropLabel">Welcome Back!</h5>
                </div>

                <form [formGroup]="loginForm">
                    <div>
                        <mat-label>Username</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Enter your username" formControlName="username" required>
                            <mat-error
                                *ngIf="loginFormControls['username'].invalid && (loginFormControls['username'].dirty || loginFormControls['username'].touched)"
                                class="error-font">
                                <small *ngIf="loginFormControls['username'].errors?.['required']">Username is
                                    required</small>
                                <small *ngIf="loginFormControls['username'].errors?.['minlength']">Username must be at
                                    least 3 characters</small>
                                <small *ngIf="loginFormControls['username'].errors?.['maxlength']">Username must not
                                    exceed 100 characters</small>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-label>Password</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput type="{{ showPassword ? 'text' : 'password' }}" id="password"
                                name="password" placeholder="Enter your password" formControlName="password" required>
                            <mat-icon class="material-symbols-rounded password-icon"
                                (click)="togglePasswordVisibility()">{{!showPassword ?
                                "visibility_off" : "visibility"}}>visibility</mat-icon>
                            <mat-error
                                *ngIf="loginFormControls['password'].invalid && (loginFormControls['password'].dirty || loginFormControls['password'].touched)"
                                class="error-font">
                                <small *ngIf="loginFormControls['password'].errors?.['required']">Password is
                                    required</small>
                                <small *ngIf="loginFormControls['password'].errors?.['minlength']">Password must be at
                                    least 8 characters</small>
                                <small *ngIf="loginFormControls['password'].errors?.['maxlength']">Password must not
                                    exceed 16 characters</small>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="modal-footer mb-5">
                <button type="button" class="btn btn-primary w-100 modal-login" [disabled]="!loginForm.valid"
                    (click)="userLogin()" data-bs-dismiss="modal">Login</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal for Add or edit Team ends -->