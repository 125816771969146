import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  public keycloak!: Keycloak;


  // Keycloak initialization
  async keycloakInit() {
    try {
      const keycloakConfig = {
        url: environment.keycloakUrl,
        realm: environment.realm,
        clientId: environment.clientID
      };
      this.keycloak = new Keycloak(keycloakConfig);
      // this.keycloak.redirectUri = 'http://localhost:8100/auth/login';
      this.keycloak.redirectUri = environment.redirectionURI;
      const authenticated = await this.keycloak.init({
        flow: 'standard',
        enableLogging: true,
        pkceMethod: 'S256'
      });
      console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);

      // Retrieve stored tokens expiry
      // const storedAccessTokenExpiry = await this.storage.get('accessTokenExpiry');
      //  var storage = await this.storage.get('refreshToken');


      // const storedRefreshToken = await this.storage.get('refreshToken');

      // if (storedRefreshToken) {
      //   if(!this.userService.getCurrentUser()){
      //     const updateuser = await this.userService.getUserMe();

      //     this.userService.setCurrentUser(updateuser);
      //     this.wonderPush.setUserId(updateuser.id);
      //     this.wonderPush.subscribeToNotifications();

      //   }
      //   this.keycloak.refreshToken = storedRefreshToken;

      //   //Update Token Whenver User Open App for fresh instance of the access token
      //   // await this.updateToken();
      // }

      // // Binding Keycloak event in ionic context
      // this.bindKeycloakEvents();
      // await this.keycloak.init({
      //   adapter: 'cordova',
      //   flow: 'standard',
      //   enableLogging: true,
      //   pkceMethod:'S256'
      //   // timeSkew:0
      // });
      // if (storedRefreshToken) {

      //   //Update Token Whenver User Open App for fresh instance of the access token
      //   await this.updateToken();
      // }

      // // const currentTime = Date.now();

      // // if(storedAccessTokenExpiry && (storedAccessTokenExpiry/1000) < currentTime){
      // //   await this.updateToken();
      // // }
      // this.startTokenRefreshTimer();


    }
    catch (error) {
      console.error('Keycloak initialization error', error);
    }
  }

  async keycloaklogin() {
    const options: Keycloak.KeycloakLoginOptions = {
      // redirectUri: 'http://localhost:8100/auth/login',
      redirectUri: `${environment.redirectionURI}/web-store/store-user?`,
      scope: 'openid offline_access'
    };
    try {
      const loginResponse: any = await this.keycloak.login(options);
      console.log("keycloak @@@@@@@@@@@", loginResponse);
    } catch (error) {
      console.error('Login failed:', error);
    }
  }

  // getKeycloakInstance(): Keycloak.KeycloakInstance {
  //   return this.keycloakInstance;
  // }

  async keycloakLogout(){
    const options = {
      redirectUri: `${environment.redirectionURI}/web-store/home?`
  };
    try {
      // await this.unsetCriticalData()
      await this.keycloak.clearToken();
      await this.keycloak.logout(options);
      console.log('Logged out successfully.');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }
}
