import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreHeaderComponent } from './store-header/store-header.component';
import { StoreFooterComponent } from './store-footer/store-footer.component';
import { AngularMaterialModule } from '../../../material-module';
import { RouterModule } from '@angular/router';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
// import { SharedModule } from '@app/@shared/shared.module';

@NgModule({
  declarations: [
    StoreHeaderComponent,
    StoreFooterComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    // NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    // SharedModule
  ],
  exports: [
    StoreHeaderComponent,
    StoreFooterComponent,
    AngularMaterialModule,
    // NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: []
})
export class StoreSharedModule { }
